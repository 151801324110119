 <template>
	<el-dialog title="光大银行开户信息" top="1vh" width="500px" :visible.sync="is_show_in_page">
		<div>
            <div style=" display: flex;">
                <div>
                    <el-button type="primary"  style="margin-bottom: 30px;margin-right:30px ;" @click="init()">刷新页面</el-button>
                    <el-button type="primary"  style="margin-bottom: 30px;margin-right:10px ;" @click="card_sync()">银行卡同步</el-button>
                </div>
                 
                  <div style="margin-top: 8px;">
                        <el-switch
                            style="display: block"
                            v-model="value2"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            active-text="公司已开户"
                            inactive-text="公司未开户"
                            @change="regist_private"
                            >
                        </el-switch>
                  </div>
            </div>
            <div v-if="shipper_info.id">
             <div class="account-info">
                 <div class="item-info">
                    <div>姓名</div>
                    <div>{{shipper_info.name}}</div>
                 </div>
                  <div class="item-info">
                    <div>光大卡号</div>
                     <div>{{shipper_info.card_num}}</div>
                 </div>
                  <div class="item-info">
                    <div>会员编号</div>
                     <div>{{shipper_info.memNum}}</div>
                 </div>
                  <div class="item-info">
                    <div>自定编号</div>
                    <div>{{shipper_info.regisName}}</div>
                 </div>
                  <div class="item-info">
                    <div >开户时间</div>
                    <div>{{shipper_info.creat_time_text}}</div>
                 </div>
             </div>
               <div class="account-info" v-for="(item,index) in shipper_cards" :key="index">
                 <div class="item-info">
                    <div>姓名</div>
                    <div>{{item.bindAccName}}</div>
                 </div>
                  <div class="item-info">
                    <div>卡号</div>
                    <div>{{item.bindAccNum}}</div>
                 </div>
                  <div class="item-info">
                    <div>银行名称</div>
                    <div>{{item.bindOpenBankName}}</div>
                 </div>
                  <div class="item-info">
                    <div>银行行号</div>
                    <div>{{item.bindOpenOrgNum}}</div>
                 </div>
                  <div class="item-info">
                    <div >状态</div>
                    <div>{{item.accState_text}}</div>
                 </div>
                  <div class="item-info">
                    <div style="margin-top: 3px;">操作</div>
                    <div><el-button type="danger"  size="mini" @click="cancellation_card(item)">解绑</el-button></div>
                 </div>
             </div>
              <div class="account-info">
                 <!-- <div class="item-info">
                    <div>577监管账户余额</div>
                    <div>{{platform_balance.busiNum}}元</div>
                 </div> -->
                <div class="item-info">
                    <div>公司余额</div>
                    <div>{{platform_balance.balance}}元</div>
                 </div>
                  <div class="item-info">
                    <div  style="margin-top: 3px;">公司绑定新卡</div>
                    <div><el-button type="primary"  size="mini" @click="add_card_company()">绑定新卡</el-button></div>
                 </div>
                 <div class="item-info1">
                    <div><el-button type="primary"  size="mini" @click="balance_add()">增加余额</el-button></div>
                    <div><el-button type="primary" style="margin-left: 20px;" size="mini" @click="balance_minus()">减少余额</el-button></div>
                 </div>
                  <!-- <div class="item-info">
                    <div>公司虚拟户余额</div>
                    <div>{{platform_balance.useableBalance}}元</div>
                 </div> -->
                  <!-- <div class="item-info">
                    <div  style="margin-top: 3px;">公司可提金额(服务费)</div>
                     <div>
                        <span style="margin-top: 3px;">{{platform_balance.balance}}元</span>
                        <el-button type="primary"  size="mini" @click="withdrawal_service()">提出到377账户</el-button>
                    </div>
                 </div> -->
             </div>
            </div>
        </div>
        <!-- 绑定新卡弹窗 -->
            <el-dialog top="20vh" title="公司绑定新卡" width="700px" :visible.sync="card_add_show" :modal="false">
                <el-form status-icon label-position="left" label-width="70px">
                    <el-form-item label="公司名称" >
                        <el-input class="el_input"  v-model="card_info.company_name"></el-input>
                    </el-form-item>
                    <el-form-item label="卡号" >
                        <el-input class="el_input"   v-model="card_info.card_num"></el-input>
                    </el-form-item>
                    <el-form-item label="总行名称" >
                        <el-input class="el_input"  v-model="card_info.bank_name"></el-input>
                    </el-form-item>
                    <el-form-item label="支行名称" >
                        <el-input class="el_input"  v-model="card_info.bank_name_full"></el-input>
                    </el-form-item>
                    <el-form-item label="银行行号" >
                        <el-input class="el_input"  v-model="card_info.bank_no"></el-input>
                    </el-form-item>
                </el-form>
                <div style="text-align:center">
                    <el-button type="primary" @click="set_card_sub">提交</el-button>
                </div>
            </el-dialog>
	</el-dialog>
	
</template>

<script>
	export default {
		props:{
			is_show:Number,
            company_code:String,
            regist_private_info:Object
		},
		data() {
			return {
                value2:false,
                api:'https://public.jungong56.com/',
				//是否显示
				is_show_in_page:false,

                shipper_info:{},

                shipper_cards:null,

                platform_balance:{},

                card_add_show:false, // 绑卡弹窗是否显示

                card_info:{
                    company_name:'',//公司名称
                    card_num:'',//银行卡号
                    bank_name:'',//银行名称
                    bank_no:'',//银行卡号
                    bank_name_full:'',//支行名称
                }
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
                  this.init()
				}else{
					//关闭弹出层
					this.is_show_in_page=false;
				}
			},
		},
		methods: {
            //余额减少
            balance_minus(){
                this.$prompt('请输入减少金额', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    }).then(({ value }) => {
                        this.$my.net.req({
                            api:this.pai,
                            data:{
                                mod:'bank_ceb',
                                ctr:'reduce_balance',
                                memNum:this.shipper_info.memNum,
                                much:value,
                            },callback:(data)=>{
                                //提醒
                                this.$my.other.msg({
                                    str:'操作成功',
                                    type:'success'
                                });
                                //刷新本页
                                this.init()
                            }
                        })
                    }).catch(() => {

                });
            },
            //余额增加
            balance_add(){
                this.$prompt('请输入增加金额', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    }).then(({ value }) => {
                        this.$my.net.req({
                            api:this.pai,
                            data:{
                                mod:'bank_ceb',
                                ctr:'add_balance',
                                memNum:this.shipper_info.memNum,
                                much:value,
                            },callback:(data)=>{
                                //提醒
                                this.$my.other.msg({
                                    str:'操作成功',
                                    type:'success'
                                });
                                //刷新本页
                                this.init()
                            }
                        })
                    }).catch(() => {

                });
            },
            //银行卡同步
            card_sync(){
                this.$my.net.req({
                    api:this.pai,
                    data:{
                        mod:'bank_ceb',
                        ctr:'sync_card_company',
                        memNum:this.shipper_info.memNum
                    },callback:(data)=>{
                        //提醒
						this.$my.other.msg({
							str:'同步成功',
							type:'success'
						});
                        //刷新本页
						this.init()
                    }
                })
            },
            regist_private(){
               	//提交
				this.$my.net.req({
                    api:this.api,
					data:{
						mod:'bank_ceb',
						ctr:'regist_company',
						company_code:this.company_code,
						company_name:this.regist_private_info.company_name,
                        legal_person:this.regist_private_info.legal_person,
                        legal_code:this.regist_private_info.legal_code,
                        card_num:this.regist_private_info.card_num,
                        cname:this.regist_private_info.cname,
					},
					callback:(data)=>{
						//提醒
						this.$my.other.msg({
							str:'开户成功',
							type:'success'
						});
								
						//刷新本页
						this.init()
					},
				});
            },
            //银行卡解绑
            cancellation_card(item){
                console.log(item)
               	//提示
				this.$my.other.confirm({
					content:"是否解绑此银行卡",
					confirm:()=>{
							
						//提交
						this.$my.net.req({
                            api:this.api,
							data:{
								mod:'bank_ceb',
								ctr:'unbind_card_company',
								company_code:this.company_code,
								card_num:item.bindAccNum

							},
							callback:(data)=>{
								//提醒
								this.$my.other.msg({
									str:'解绑成功',
									type:'success'
								});
								
								//刷新本页
								this.init()
							},
						});
					},
				});
            },
            //提交绑定的银行卡
            set_card_sub(){
                this.$my.net.req({
                    api:this.api,
                    data:{
                        mod:'bank_ceb',
                        ctr:'bind_card_company',
                        company_code:this.company_code,
                        company_name:this.card_info.company_name,
                        card_num:this.card_info.card_num,
                        bank_name:this.card_info.bank_name,
                        bank_no:this.card_info.bank_no,
                        bank_name_full:this.card_info.bank_name_full
                    },
                    callback:(data)=>{
                     	this.$my.other.msg({
							type:'success',
							str:'绑卡成功'
						});
                        this.card_info.company_name='',
                        this.card_info.card_num='',
                        this.card_info.bank_name='',
                        this.card_info.bank_no=''
                        this.card_add_show = false
                        this.init()
                    }
                })
            },
            //绑定新卡
            add_card_company(){
                this.card_add_show = true
                this.card_info.company_name = this.regist_private_info.company_name
            },
            //服务费提现
            withdrawal_service(){
                this.$my.net.req({
                    api:this.api,
                    data:{
                        mod:'bank_ceb',
                        ctr:'get_service_auto',
                    },
                    callback:(data)=>{
                     	this.$my.other.msg({
							type:'success',
							str:'提现成功'
						});
                        this.init()
                    }
                })
            },
            //初始化
            init(){
                this.$my.net.req({
					api:this.api,
					data:{
						mod:'bank_ceb',
						ctr:'get_gd_info',
						company_code:this.company_code,
						type:'shipper'
					},
					callback:(data)=>{
                        data.info.creat_time_text = this.$my.other.totime(data.info.creat_time);
                        data.cards.forEach(item=>{
                            if(item.accState == 1){
                                item.accState_text = '正常'
                            }else{
                                item.accState_text = '异常'
                            }
                        })
                        if(data.info.id){
                            this.value2 = true
                        }else{
                            this.value2 = false
                        }
						this.shipper_cards = data.cards
						this.shipper_info = data.info
                        //查询577账户余额
                        this.$my.net.req({
                            api:this.api,
                            data:{
                                mod:'bank_ceb',
                                ctr:'get_balance_company',
                                company_code:this.company_code,
                            },
                            callback:(data)=>{
                                //打开弹出层
                                this.is_show_in_page=true;
                                this.platform_balance = data.body
                            }
                        })
					}
				})
            },
		}
	}
</script>
<style lang="scss" scoped>
.account-info{
    margin-left: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;

    .item-info{
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin: auto auto;
        margin-top: 10px;
        
    }
    .item-info1{
        margin: auto auto;
        width: 80%;
        display: flex;
        margin-top: 10px;
    }
}

</style>