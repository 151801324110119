 <template>
	<el-dialog :title="page_title" top="1vh" width="1080px" :visible.sync="is_show_in_page" @close="shipper_close">
		
		<!-- 表单 -->
		<el-form label-width="80px" style="margin:-15px 0 20px 0">


			<div class="big_tit" style="margin:0">法人信息</div>
			<div class="tab1_inner" style="margin-bottom:20px">
				<el-form-item  class="el_form_item" label="法人手机号" label-width="90px">
					<el-input v-model="legal_tel">
					
					</el-input>
				</el-form-item>
				<el-form-item  class="el_form_item" label="法人身份证号" label-width="120px">
					<el-input v-model="legal_code">
					
					</el-input>
				</el-form-item>
			</div>
			<div class="big_tit" style="margin:0">发车/到货</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="发车条件" style="width:250px">
					<el-select @change="sava('condition_of_tord_start')" class="el_inner_width" v-model="form.condition_of_tord_start">
						<el-option label="无" value="1"></el-option>
						<el-option label="区划校验" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="发车凭证" style="width:250px">
					<el-select @change="sava('truck_tord_start_voucher_upl_mode')" class="el_inner_width" v-model="form.truck_tord_start_voucher_upl_mode">
						<el-option label="自愿上传" value="1"></el-option>
						<el-option label="提示上传" value="2"></el-option>
						<el-option label="强制上传" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="到货条件" style="width:250px">
					<el-select @change="sava('condition_of_tord_arrived')" class="el_inner_width" v-model="form.condition_of_tord_arrived">
						<el-option label="无" value="1"></el-option>
						<el-option label="区划校验" value="2"></el-option>
						<!-- <el-option label="间隔30分钟" value="3"></el-option> -->
					</el-select>
				</el-form-item>
				<!-- <el-form-item class="el_form_item" label="到货凭证" style="width:250px">
					<el-select @change="sava('truck_tord_arrived_voucher_upl_mode')" class="el_inner_width" v-model="form.truck_tord_arrived_voucher_upl_mode">
						<el-option label="自愿上传" value="1"></el-option>
						<el-option label="提示上传" value="2"></el-option>
						<el-option label="强制上传" value="3"></el-option>
					</el-select>
				</el-form-item> -->
			</div>
			<div class="tab1_inner">
				<el-form-item  class="el_form_item" label="运单推迟支付时间" label-width="130px">
					<el-input v-model="act_postpone">
						<el-button slot="append">月</el-button>
					</el-input>
				</el-form-item>
				<el-form-item  class="el_form_item" label="接单间隔时间" label-width="130px">
					<el-input v-model="act_interval">
						<el-button slot="append">分钟</el-button>
					</el-input>
				</el-form-item>
			</div>

			<div class="big_tit">允许最大单价/预付款最大比例(0 - 100%)</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="最大单价" style="width:490px">
					<el-input v-model="form.freight_unit_price_max">
						<el-button slot="append">元/公里</el-button>
						<el-button @click="sava('freight_unit_price_max')" slot="append">修改</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="最大比例" style="width:490px;margin-left: 10px;">
					<el-input v-model="form.freight_not_arrived_pay_percent_max">
						<el-button slot="append">%</el-button>
						<el-button @click="sava('freight_not_arrived_pay_percent_max')" slot="append">修改</el-button>
					</el-input>
				</el-form-item>
			</div>


			<div class="big_tit">申请开票条件/额外服务费率/额外服务费计算方式</div>
			<div class="tab1_inner">
				<!-- <el-form-item class="el_form_item" label="开票条件" style="width:320px">
					<el-select @change="sava('condition_of_invoice_apply')" class="el_inner_width" v-model="form.condition_of_invoice_apply">
						<el-option label="部分支付运费" value="1"></el-option>
						<el-option label="完全支付运费" value="2"></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item class="el_form_item" label="服务费率" style="width:364px;">
					<el-input v-model="form.extra_service_charge_rate">
						<el-button slot="append">个点</el-button>
						<el-button @click="sava('extra_service_charge_rate')" slot="append">修改</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="计算方式" style="width:320px;">
					<el-select @change="sava('extra_service_charge_type')" class="el_inner_width" v-model="form.extra_service_charge_type">
						<el-option label="除法" value="1"></el-option>
						<el-option label="乘法" value="2"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="保费点数" style="width:364px;">
					<el-input v-model="insure_num">
						<el-button slot="append">个点</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="big_tit">所属大区</div>
			<div class="tab1_inner">
				<el-form-item  class="el_form_item" label="所属大区" >
					<el-select @change="edit_company('area_id')" class="el_inner_width" v-model="form.area_id">
						<el-option label="不限" value="0"></el-option>
						<el-option v-for="item in area_list" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="销售绑定" >
					<el-select  @change="edit_company()" class="el_inner_width" v-model="form.saler_id">
						<el-option label="不限" value="0"></el-option>
						<el-option v-for="item in sale_list" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="运营绑定" >
					<el-select  class="el_inner_width" v-model="form.admin_id">
						<el-option label="不限" value="0"></el-option>
						<el-option v-for="item in admin_list" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="代理绑定" style="width:285px;margin-top: 20px;margin-bottom: 20px;">
					<el-select  @change="edit_company()" class="el_inner_width" v-model="form.agent_id">
						<el-option label="不限" value="0"></el-option>
						<el-option v-for="item in agent_list" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="备注" style="width:285px;margin-top: 20px;margin-bottom: 20px;">
					<el-input v-model="company_msg">
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item"  label-width="160px" label="徽商打款短信提醒" style="width:310px;margin-top: 20px;margin-bottom: 20px;">
					<el-select class="el_inner_width" v-model="act_sms">
						<el-option label="已开启" value="1"></el-option>
						<el-option label="未开启" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="公司别名" style="width:285px;margin-bottom: 20px;">
					<el-input v-model="alias">
					</el-input>
				</el-form-item>
			</div>
			<div class="big_tit" style="margin:0">合同上传</div>
			<div class="tab1_inner">
					<div class="el_form_item" style="width:48%;margin-left:1%">
					<div class="img_btn">
						<div v-if="!company_img_src" class="btn">未上传</div>
						  <el-image
								v-else
								class="img"
								:src="company_img_src"
								:previewSrcList="companyList"
								:z-index = 3000
								>
							</el-image>
					</div>
					<div class="bottom_text">合同图片
						<el-button class="btn_left" size="mini" type="text" @click="voucher_add()">上传</el-button>
					</div>
				</div>
			</div>
			<div class="big_tit" style="margin:0">e签宝合同</div>
			<div class="tab1_inner company_contract">
				<div>
					<el-button @click="contract_upload_truck()">陆运模板下载</el-button>
                  	<el-button @click="company_contract(1)">陆运合同上传</el-button>
			    	<el-button v-if="data.e_url" @click="company_contract_url(data.e_url,1)">陆运合同下载</el-button>
					<el-button v-if="data.e_path" @click="company_contract_see(data.e_path)">陆运合同查看</el-button>
				</div>
			</div>
			<div class="tab1_inner company_contract">
			    <div>
					<el-button @click="contract_upload_ship()">船运模板下载</el-button>
					<el-button @click="company_contract(2)">船运合同上传</el-button>
			    	<el-button v-if="data.e_curl" @click="company_contract_url(data.e_curl,2)">船运合同下载</el-button>
					<el-button v-if="data.e_cpath" @click="company_contract_see(data.e_cpath)">船运合同查看</el-button>
				</div>
			</div>
		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="edit_mark()">提交</el-button>
			<el-button type="primary" @click="sync_to_tord()">同步到运单</el-button>
		</div>
		<!-- 凭证添加界面 -->
		<el-dialog
			title="证件上传"
			top="2vh"
			width="500px"
			:visible.sync="upl.is_show"
			:modal="false"
			>
			<div class="upl">
				<div class="img_area" @click="voucher_add_sub">
				<img v-if="upl.src" :src="upl.src" class="show" />
				<i v-else class="el-icon-plus avatar-uploader-icon icon"></i>
				</div>

				<div class="btn_area">
				<el-button type="primary" @click="voucher_sub()">上传</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog
			title="查看合同"
			:visible.sync="company_pdf.see_is_show"
			width="80%"
			top="1vh"
			:modal="false"
		>
			<!-- <pdf
				v-for="item in pageTotal"
				:src="company_pdf.url"
				:key="item"
				:page="item"
			>
			</pdf> -->
			<iframe :src="company_pdf.url" style="height:800px; width:100%;margin:0;border:0;"></iframe>
		</el-dialog>
		<el-dialog
			title="e签宝合同签署"
			:visible.sync="company_contract_data.is_show"
			width="80%"
			top="1vh"
			:modal="false"
			@close="pdf_upload_close"
		>
		<div>
			<el-button type="primary"  v-if="!company_pdf.up_show" @click="company_upload_show()">上传合同</el-button>
			<el-button type="primary" v-if="!company_pdf.up_show" @click="pdf_upload_confirm()">确认上传</el-button>
		</div>
		
		<!-- <span>请选择签署合同类型</span> -->
		<span slot="footer" class="dialog-footer">
			<div id="pdf_upload">
				<el-upload
					v-if="company_pdf.up_show"
					action="#"
					list-type="picture-card"
					:before-upload="company_upload"
					accept='.pdf'
					>
					<i class="el-icon-plus"></i>
				</el-upload>
			</div>

			<!-- <pdf
				v-for="item in pageTotal"
				:src="company_pdf.src"
				:key="item"
				:page="item"
			>
			</pdf> -->
			<iframe :src="company_pdf.src" style="height:800px; width:100%;margin:0;border:0;"></iframe>
		</span>
		</el-dialog>
		<contarctUploaTruck :is_show="contract_truck_data.is_show" :data="contract_truck_data.data" :type="contract_truck_data.type" @contract_up ='contarct_up_success'></contarctUploaTruck>

		<contarctUploaShip :is_show="contract_ship_data.is_show" :data="contract_ship_data.data" :type="contract_ship_data.type" @contract_up ='contarct_up_success'></contarctUploaShip>
	</el-dialog>
	
</template>

<script>
import pdf from 'vue-pdf'
import axios from 'axios';
import contarctUploaTruck from './contract_upload_truck.vue'
import contarctUploaShip from './contract_upload_ship.vue'
	export default {
		components:{
			pdf,
			contarctUploaTruck,
			contarctUploaShip
		},
		props:{
			is_show:Number,
			user_num:String,
			company_name:String,
			id:String,
			shipper_mark:String,
			data:''
		},
		data() {
			return {
				//合同下载弹窗 陆运
				contract_truck_data:{
					is_show:0,//弹窗是否显示
					data:{},//页面数据
					type:0,//1路运 2船运
				},
				//合同下载弹窗 船运
				contract_ship_data:{
					is_show:0,//弹窗是否显示
					data:{},//页面数据
					type:0,//1路运 2船运
				},
				companyList:[],
				company_img_src:'',
				agent_list:[],//代理列表
				//销售列表
				sale_list:[],
				admin_list:[],//运营列表
				//大区列表
				area_list:[
					{name:"湖南大区",id:7},
					{name:"贵州大区",id:8},
					{name:"安徽大区",id:9},
					{name:"河南大区",id:3},
					{name:"运营风控中心",id:10}
				],

				legal_tel:'',//法人手机号
				legal_code:'',//法人身份证号
				//合同签署参数
				company_contract_data:{
					is_show:false,
					type:'',//上传类型
				},
				company_pdf:{
					src:'',//转换的pdf地址
					up_show:true,
					key:'',//上传七牛云的key
					see_is_show:false,//查看合同弹窗是否显示
					url:'',//pdf查看链接
				},
				//是否显示
				is_show_in_page:false,

				//页面标题
				page_title:'',

	            insure_num:'',//保费点数
				//货主参数
				form:{
					
					//开票申请条件(1:部分支付,2:完全支付)
					condition_of_invoice_apply:'',//

					//额外服务费率
					extra_service_charge_rate:'',//

					//额外服务费计算方式(1:除法模式,2:乘法模式)
					extra_service_charge_type:'',//

					//允许运费单价最大值(单位:元/公里)
					freight_unit_price_max:'',//

					//运费未到货时付款总量占总运费最大比例
					freight_not_arrived_pay_percent_max:'',//

					//到货后自动校验轨迹(1:自动校验,2:不自动校验)
					auto_trajectory_check_of_tord_arrived:'',

					//发车条件(1:无,2:使用经纬度校验)
					condition_of_tord_start:'',//

					//到货条件(1:无,2:使用经纬度校验,3:时间间隔30分钟)
					condition_of_tord_arrived:'',//

					//发车凭证上传模式(1:自愿,2:自动打开上传界面,3:强制上传)
					truck_tord_start_voucher_upl_mode:'',//

					//到货凭证上传模式(1:自愿,2:自动打开上传界面,3:强制上传)
					truck_tord_arrived_voucher_upl_mode:'',//
					
					//所属大区id
					area_id:'0',

					saler_id:'0',//销售id

					admin_id:'0',//运营id

					img_key:'',//合同图片

					agent_id:'',//代理id

					e_path:'',//陆运合同
                   
				    e_cpath:'',//船运合同
				},
				act_postpone:'',//运单推迟时间

				act_interval:'',//接单间隔时间

				act_sms:'',//短信提醒是否开启
				alias:'',//公司别名

				mark:'',//公司备注
				//上传界面
				upl: {
					//是否显示上传界面
					is_show: false,

					//地址
					src: "",
				},
				company_msg:'',//备注

				pageTotal:null,
				pdfUrl:null
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.get_shipper_set()
					//页面标题
					this.page_title=`${this.company_name}的参数`

					//打开弹出层
					this.is_show_in_page=true;

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			},
			mark(new_data){
				this.mark = new_data
				
			},
		},
		methods: {
			//合同上传七牛云成功
			contarct_up_success(type,key){
				if(type ==1){
					this.form.e_path =key
				}else if(type ==2){
					this.form.e_cpath = key
				}
			},
			contract_upload_ship(){
			    this.contract_ship_data.is_show++
				this.contract_ship_data.data = this.data
				// this.contract_upload.type = type
			},

			contract_upload_truck(){
				this.contract_truck_data.is_show++
				this.contract_truck_data.data = this.data
				// this.contract_upload.type = type
			},
			//合同查看
			company_contract_see(url){
				if(!url){
					this.$my.other.msg({
						type:'info',
						str:'当前合同未上传，无法预览'
					});
				}else{
					var src = this.$my.qiniu.make_src('pdf',url)
					console.log(src)
					this.company_pdf.url = src
					this.company_pdf.see_is_show = true
				}
				// var src = this.$my.qiniu.make_src('drivercy',url)
				// console.log(src)
				// this.company_pdf.url = pdf.createLoadingTask(src)
				// this.company_pdf.url.promise.then(pdf => this.pageTotal = pdf.numPages).catch(error => {})
				
			},
			//合同下载
			company_contract_url(url,type){

				let name =type==1?'e签宝路运合同':'e签宝船运合同'
				const a = document.createElement('a')
				a.href = url
				a.setAttribute('download', name)
				a.click()
			},
			//合同确认上传
			pdf_upload_confirm(){
				if(this.company_contract_data.type ==1){
					this.form.e_path = this.company_pdf.key
				}else if(this.company_contract_data.type ==2){
					this.form.e_cpath = this.company_pdf.key
				}
				this.company_contract_data.is_show = false
			},
			//弹窗关闭
			pdf_upload_close(){
				this.company_contract_data={
					is_show:false,
					type:'',//上传类型
				},
				this.company_pdf={
					src:'',//转换的pdf地址
					up_show:false,
					key:'',//上传七牛云的key
					see_is_show:false
				}
			},
			//打开上传
			company_upload_show(){
				this.company_pdf.up_show = true
			},
			//上传pdf文件
			company_upload(file){
				this.company_pdf.src = ''
				let res={
					name:file.name,
					size:file.size,
					type:file.type,
				};
				if(window.createObjectURL!=undefined){ // basic
					res.src = window.createObjectURL(file);
				}else if(window.URL!=undefined){ // mozilla(firefox)
					res.src=window.URL.createObjectURL(file) ;
				}else if(window.webkitURL!=undefined){ // webkit or chrome
					res.src=window.webkitURL.createObjectURL(file) ;
				}

				this.$my.qiniu.files_cache[res.src] = file
				//上传pdf
				this.$my.qiniu.upl({
					bucket:'pdf',
					key:'',
					file_path:res.src,
					callback:(data)=>{
						this.company_pdf.key = data.key
						// var src = this.$my.qiniu.make_src('drivercy',data.key)
						// this.company_pdf.src = pdf.createLoadingTask(src)
						// this.company_pdf.src.promise.then(pdf => this.pageTotal = pdf.numPages).catch(error => {})
						var src = this.$my.qiniu.make_src('pdf',data.key)
						this.company_pdf.src = src
						this.company_pdf.up_show = false
					}
				});
			},
			//打开弹窗
			company_contract(type){
				this.company_contract_data.type =type
				this.company_contract_data.is_show = true
			},
			// 弹窗关闭
			shipper_close(){

			},
			voucher_add() {
				//打开添加凭证界面

				//打开上传界面
				this.upl.is_show = true;

				//清空上次上传的图片
				this.upl.src = "";
			},
			voucher_add_sub() {
				//提交凭证

				//选择图片
				this.$my.qiniu.choose_img({
					success: (res) => {
					//预览
					this.upl.src = res.src;
					},
				});
			},
			voucher_sub() {
				//上传
				this.$my.qiniu.upl({
					bucket: "driversourceproof",
					file_path: this.upl.src,
					callback: (data) => {
				     	var key = data.key.split('__v__1')
						this.form.img_key = key[0]
						this.company_img_src = this.upl.src
					    this.companyList = [this.upl.src];
						this.upl.is_show = false;
					},
				});
			},
			//同步到运单
			sync_to_tord(){
				//询问
				this.$my.other.confirm({
					content:"点击'确定'更新此货主名下的运单参数",
					confirm:()=>{
						
						//调接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_set_sync_by_admin',
								shipper_user_num:this.user_num,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.is_show_in_page=false;
								this.$emit('success')
							},
						});
					}
				});
			},
			edit_mark(){
				console.log(this.form.agent_id)
				//表单
				let form={
					mod:'app_user',
					ctr:'company_edit_admin',
					id:this.id,
					company_msg:this.company_msg,
					saler_id:this.form.saler_id,
					admin_id:this.form.admin_id,
					area_id:this.form.area_id,
					insure_num:this.insure_num,
					company_img:this.form.img_key,
					agent_id:this.form.agent_id,
					act_postpone:this.act_postpone,
					act_sms:this.act_sms,
					act_interval:this.act_interval,
					e_cpath:this.form.e_cpath,
					e_path:this.form.e_path,
					legal_tel:this.legal_tel,
					legal_code:this.legal_code,
					alias:this.alias,
					mark:this.mark
				}
				
				//提交数据
				this.$my.net.req({
					data:form,
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'保存成功'
						});
						this.is_show_in_page=false;
						this.$emit('success')
					},
				});
			},
			edit_company(key){
				if(key){
					this.sale_list = []
					this.form.saler_id = ""
					this.form.agent_id = ""
					this.agent_list=[]
				}

				//读取销售列表
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_saler_list',
						area_id:this.form.area_id,
						type:1
					},
					callback:(data)=>{
						if(data.list){
							this.sale_list = data.list.list
						}
						
					}
				})
				//读取代理列表
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_saler_list',
						area_id:this.form.area_id,
						type:2
					},
					callback:(data)=>{
						if(data.list){
							this.agent_list = data.list.list
						}
						
					}
				})
				// //表单
				// let form={
				// 	mod:'app_user',
				// 	ctr:'company_edit_admin',
				// 	user_num:this.user_num,
				// }
				// //置入修改后的参数
				// form[key]=this.form[key]
				
				// //提交数据
				// this.$my.net.req({
				// 	data:form,
				// 	callback:(data)=>{
				// 		this.$my.other.msg({
				// 			type:'success',
				// 			str:'修改成功'
				// 		});
				// 	},
				// });
			},
			
			//保存
			sava(key){

				//表单
				let form={
					mod:'app_user',
					ctr:'shipper_extend_edit_admin',
					user_num:this.user_num,
					set:{}
				}

				//置入修改后的参数
				form.set[key]=this.form[key]
				//特殊处理
				if(key=='freight_not_arrived_pay_percent_max'){//预付款最大比例(0 - 100%)
					form.set.freight_not_arrived_pay_percent_max=this.form.freight_not_arrived_pay_percent_max/100
				}

				//提交数据
				this.$my.net.req({
					data:form,
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'修改成功'
						});
					},
				});
			},

			//读取数据
			get_shipper_set(){
				this.sale_list = []
				this.area_list = []
				this.admin_list = []
				//读取运营列表
				this.$my.net.req({
                    data:{
						mod:'admin',
						ctr:'user_list_by_admin',
                    },callback:(data)=>{
						if(data.list){
							this.admin_list = data.list
						}
                        
                    }
                })
                this.$my.net.req({
                    data:{
                        mod:'app_user',
                        ctr:'app_user_area_list',
                        name:this.name
                    },callback:(data)=>{
						if(data.list){
						var data = data.list.list
							data.forEach(item => {
								this.area_list.push({
									name:item.name,
									id:item.id
								})
							});
						}
                    }
                })
				//读取数据
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'shipper_extend_list_admin',
						user_num:this.user_num
					},
					callback:(data)=>{
						var data=data.list[0]
						data.freight_not_arrived_pay_percent_max=(data.freight_not_arrived_pay_percent_max*100).toFixed(0)

						//置入数据
						this.form=data
						//置入大区id
						if(this.data.area_id!=0){
							this.form.area_id=this.data.area_id
						}
						// else{
						// 	this.form.area_id = ""
						// }
						// if(this.data.saler_id==0){
						// 	this.form.saler_id=""
						// }
						// if(this.data.agent_id==0){
						// 	this.form.agent_id=""
						// }
						//读取销售列表
						this.$my.net.req({
							data:{
								mod:'app_user',
								ctr:'app_user_saler_list',
								area_id:this.data.area_id,
								type:1
							},
							callback:(data)=>{
								if(data.list){
									this.sale_list = data.list.list
									if(this.data.saler_id!=0){
										 this.form.saler_id=this.data.saler_id
									}
								}
								
							}
						})
						this.$my.net.req({
							data:{
								mod:'app_user',
								ctr:'app_user_saler_list',
								area_id:this.data.area_id,
								type:2
							},
							callback:(data)=>{
								if(data.list){
									this.agent_list = data.list.list
									if(this.data.agent_id!=0){
										this.form.agent_id=this.data.agent_id
									}
									
								}
								
							}
						})
						if(this.data.admin_id!=0){
							this.form.admin_id=this.data.admin_id
						}
					}
				})
				this.act_postpone=this.data.act_postpone
				this.act_sms = this.data.act_sms
				this.company_msg = this.data.company_msg
				this.act_interval=this.data.act_interval
				this.alias = this.data.alias
			
				this.insure_num=this.data.insure_num,
				this.legal_tel = this.data.legal_tel
				this.legal_code = this.data.legal_code
				this.company_img_src=this.$my.qiniu.make_src('drivercy',this.data.company_img)
				this.companyList[0]=this.$my.qiniu.make_src('drivercy',this.data.company_img)
			},
		}
	}
</script>
<style lang="scss" scoped>
.upl {
  .img_area {
    border: 1px solid #ccc;
    cursor: pointer;
    padding: 5px;
    .show {
      margin: auto;
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
    .icon {
      text-align: center;
      display: block;
      font-size: 100px;
      height: 500px;
      line-height: 500px;
    }
  }
  .mark_area {
    border: 1px solid #ccc;
    padding: 5px;
    width: 448px;
    margin-top: 10px;
    height: 100px;
    outline: none;
    font-size: 16px;
  }
  .mark_area::placeholder {
    font-size: 20px;
    line-height: 100px;
    text-align: center;
  }
  .btn_area {
    text-align: center;
    margin-top: 15px;
  }
}
	.el_form_item {
		margin: 0;
		.img_btn {
			border: 1px solid #bbb;
			border-radius: 5px;
			cursor: pointer;
			padding: 5px;
			height: 300px;
			overflow: hidden;
			.btn {
			text-align: center;
			line-height: 290px;
			font-size: 14px;
			}

			.img {
			position: relative;
			display: block;
			height: 290px;
			}
		}
		  .bottom_text {
			margin-top: 5px;
			text-align: center;
		}
	}
	
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}
	.tord_real_time_add_bottom_btns{
		margin-top: 40px;
		text-align: center;
	}
	.bottom_btns{
		text-align: center;
		margin-top: 30px;
	}
	.el-dialog-s{
		z-index: 11;
	}
	.company_contract{
		display: flex;
		justify-content: space-around;
	}
	#pdf_upload{
		margin-right: 100%;
	}
</style>