 <template>
	<el-dialog title="项目列表" top="1vh" width="1500px" :visible.sync="is_show_in_page">
        <div class="page">
              <!-- <el-button type="primary" @click="project_add">添加</el-button> -->
             <div class="zwb_list">
               
                 <div class="tab_height">
                    <el-table :data="list.data" :border="true" :stripe="true" size="small" height="800px">
                        <el-table-column label="添加时间" width="300">
                            <template slot-scope="scope">
                                <div class="tab_line_item">{{scope.row.creat_time_text}}</div>
                            </template>
                        </el-table-column>
                       <el-table-column label="姓名" width="200">
                            <template slot-scope="scope">
                                <div>{{scope.row.sub_user_info.name}}</div>
                            </template>
                        </el-table-column>
                       <el-table-column label="手机号码" width="200">
                            <template slot-scope="scope">
                                <div>{{scope.row.sub_user_info.tel}}</div>
                            </template>
                        </el-table-column>
                       	<el-table-column label="子账户余额" width="200">
                            <template slot-scope="scope">
                                <div class="tab_line_item" style="margin-right: 20px;">{{scope.row.price}}  </div>
                            </template>
                        </el-table-column>
                       <el-table-column label="类型" width="200">
                            <template slot-scope="scope">
                                <div>{{scope.row.type_text}}</div>
                            </template>
                        </el-table-column>
                       <el-table-column label="状态" width="200">
                            <template slot-scope="scope">
                                <div>{{scope.row.status_text}}</div>
                            </template>
                        </el-table-column>
                         <el-table-column label="备注" width="200">
                            <template slot-scope="scope">
                                <div>{{scope.row.mark}}</div>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column label="操作" width="120">
                            <template slot-scope="scope">
                            
                                <el-button  @click="edit(scope.row)" size="mini" type="text">编辑</el-button>

                            </template>
                        </el-table-column> -->
                    </el-table>
                </div>
            </div>
        </div>
	</el-dialog>
  
</template>

<script>
	export default {
		props:{
			is_show:Number,
            shipper_num:String,
            
		},
		data() {
			return {
                list:{
                    data:[]
                },
                //添加数据
                project_data:{
                    is_show:0,
                    user_num:'',//货主编号
                },
                //编辑数据
                project_edit:{
                    is_show:0,
                    data:{}
                },

                is_show_in_page:false
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
                    this.get_page_data()
					this.is_show_in_page=true;

				}else{
					//关闭弹出层
					this.is_show_in_page=false;
				}
			},
		},
		methods: {
            //编辑项目
            edit(item){
                this.project_edit.is_show ++
                this.project_edit.data = item
            },
            //读取数据
            get_page_data(){
                this.$my.net.req({
                    data:{
                        mod:'truck_sub_user',
                        ctr:'sub_user_list_by_main_user',
                        shipper_num:this.shipper_num,
                    },callback:(data)=>{
                    //预处理
						for(let item of data.list){

							//成交时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);
							
							//状态
							switch(item.status){
								case '1':item.status_text="正常";break;
								case '2':item.status_text="停用";break;
							}
							
							//类型(1:全权限,2:仅能查看全部数据,3:只能查看自己的数据)
							switch(item.type){
								case '1':item.type_text="全数据";break;
								case '2':item.type_text="部分数据";break;
							}
						}

						//渲染
						this.list.data=data.list
                    }
                })
            }
		}
	}
</script>
<style lang="scss" scoped>  
    .zwb_list{
        width: 100%;
        margin-top: 20px;
    }

</style>