 <template>
	<el-dialog title="项目列表" top="1vh" width="1500px" :visible.sync="is_show_in_page">
        <div class="page">
              <el-button type="primary" @click="project_add">添加</el-button>
             <div class="zwb_list">
               
                 <div class="tab_height">
                    <el-table :data="list" :border="true" :stripe="true" size="small" height="800px">
                        <el-table-column label="项目名称" width="220">
                            <template slot-scope="scope">
                                <div>{{scope.row.item_name}}</div>
                            </template>
                        </el-table-column>
                       <el-table-column label="发车条件" width="120">
                            <template slot-scope="scope">
                                <div>{{scope.row.condition_of_tord_arrived_text}}</div>
                            </template>
                        </el-table-column>
                       <el-table-column label="发车凭证" width="120">
                            <template slot-scope="scope">
                                <div>{{scope.row.truck_tord_start_voucher_upl_mode_text}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="到货条件" width="120">
                            <template slot-scope="scope">
                                <div>{{scope.row.condition_of_tord_arrived_text}}</div>
                            </template>
                        </el-table-column>
                       <el-table-column label="到货凭证" width="120">
                            <template slot-scope="scope">
                                <div>{{scope.row.truck_tord_arrived_voucher_upl_mode_text}}</div>
                            </template>
                        </el-table-column>
                       <el-table-column label="最大单价" width="120">
                            <template slot-scope="scope">
                                <div>{{scope.row.freight_unit_price_max}}元/公里</div>
                            </template>
                        </el-table-column>
                         <el-table-column label="最大比例" width="120">
                            <template slot-scope="scope">
                                <div>{{scope.row.freight_not_arrived_pay_percent_max}}%</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="开票条件" width="120">
                            <template slot-scope="scope">
                                <div>{{scope.row.condition_of_invoice_apply_text}}</div>
                            </template>
                        </el-table-column>
                         <el-table-column label="服务费率" width="120">
                            <template slot-scope="scope">
                                <div>{{scope.row.extra_service_charge_rate}}</div>
                            </template>
                        </el-table-column>
                         <el-table-column label="计算方式" width="120">
                            <template slot-scope="scope">
                                <div>{{scope.row.extra_service_charge_type_text}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="120">
                            <template slot-scope="scope">
                            
                                <el-button  @click="edit(scope.row)" size="mini" type="text">编辑</el-button>

                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <!-- 添加弹窗 -->
        <Add :is_show="project_data.is_show" :user_num="project_data.user_num" @success="get_page_data"></Add>

        <!-- 编辑弹窗 -->
        <Edit :is_show="project_edit.is_show" :data="project_edit.data" @success="get_page_data"></Edit>
	</el-dialog>
  
</template>

<script>
import Add from './add.vue'
import Edit from './edit.vue'
	export default {
        components:{
            Add,
            Edit
        },
		props:{
			is_show:Number,
            data:Object,
            
		},
		data() {
			return {
                list:[],
                //添加数据
                project_data:{
                    is_show:0,
                    user_num:'',//货主编号
                },
                //编辑数据
                project_edit:{
                    is_show:0,
                    data:{}
                },

                is_show_in_page:false
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
                    this.get_page_data()
					this.is_show_in_page=true;

				}else{
					//关闭弹出层
					this.is_show_in_page=false;
				}
			},
		},
		methods: {
            //编辑项目
            edit(item){
                this.project_edit.is_show ++
                this.project_edit.data = item
            },
            //添加项目
            project_add(){
                this.project_data.is_show ++
                this.project_data.user_num = this.data.user_num
            },
            //读取数据
            get_page_data(){
                this.$my.net.req({
                    data:{
                        mod:'app_user',
                        ctr:'company_item_list',
                        user_num:this.data.user_num
                    },callback:(data)=>{
                        //预处理
                        for(let item of data.list){
                            item.freight_not_arrived_pay_percent_max = (item.freight_not_arrived_pay_percent_max *100).toFixed(2)
                            //开票申请条件(1:部分支付,2:完全支付)
							switch(item.condition_of_invoice_apply){
								case '1':item.condition_of_invoice_apply_text='部分支付';break;
								case '2':item.condition_of_invoice_apply_text='完全支付';break;
							}

                            //到货条件(1:无,2:使用经纬度校验,3:时间间隔30分钟)
                            switch(item.condition_of_tord_arrived){
								case '1':item.condition_of_tord_arrived_text='无';break;
								case '2':item.condition_of_tord_arrived_text='使用经纬度校验';break;
                                case '3':item.condition_of_tord_arrived_text='时间间隔30分钟';break;
							}

                            //发车条件(1:无,2:使用经纬度校验)
                            switch(item.condition_of_tord_start){
								case '1':item.condition_of_tord_arrived_text='无';break;
								case '2':item.condition_of_tord_arrived_text='使用经纬度校验';break;
							}

                            //额外服务费计算方式(1:除法模式,2:乘法模式)
                            switch(item.extra_service_charge_type){
								case '1':item.extra_service_charge_type_text='除法模式';break;
								case '2':item.extra_service_charge_type_text='乘法模式';break;
							}

                           	//到货凭证上传模式(1:自愿,2:自动打开上传界面,3:强制上传)
                            switch(item.truck_tord_arrived_voucher_upl_mode){
								case '1':item.truck_tord_arrived_voucher_upl_mode_text='自愿';break;
								case '2':item.truck_tord_arrived_voucher_upl_mode_text='自动打开上传界面';break;
                                case '2':item.truck_tord_arrived_voucher_upl_mode_text='强制上传';break;
							}

                            //发车凭证上传模式(1:自愿,2:自动打开上传界面,3:强制上传)
                            switch(item.truck_tord_start_voucher_upl_mode){
								case '1':item.truck_tord_start_voucher_upl_mode_text='自愿';break;
								case '2':item.truck_tord_start_voucher_upl_mode_text='自动打开上传界面';break;
                                case '3':item.truck_tord_start_voucher_upl_mode_text='强制上传';break;
							}
                        }
                        this.list = data.list
                    }
                })
            }
		}
	}
</script>
<style lang="scss" scoped>  
    .zwb_list{
        width: 100%;
        margin-top: 20px;
    }

</style>